import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import ResponsiveSidebar from "../components/ResponsiveSidebar/ResponsiveSidebar"
import SidenavNavigation from "../components/SidenavNavigation/SidenavNavigation"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      excerpt
      html
      frontmatter {
        title
      }
    }
  }
`

const BombeoTemplate = ({ data: { markdownRemark: bombeo } }) => {
  const sidenavNavigationListItems = [
    {
      title: "Sistemas de agua caliente y calefacción",
      url: "/bombeo/sistemas-agua-caliente-calefaccion",
    },
    {
      title: "Sistemas de refrigeración y aire acondicionado",
      url: "/bombeo/sistemas-refrigeracion-aire-acondicionado",
    },
    {
      title: "Aplicaciones industriales",
      url: "/bombeo/aplicaciones-industriales",
    },
    {
      title: "Aumento de presión y trasiego de líquidos",
      url: "/bombeo/aumento-presion-trasiego-liquidos",
    },
    {
      title: "Suministro de agua subterránea",
      url: "/bombeo/suministro-agua-subterranea",
    },
    {
      title: "Suministro de agua doméstica",
      url: "/bombeo/suministro-agua-domestica",
    },
    {
      title: "Aguas residuales",
      url: "/bombeo/aguas-residuales",
    },
    {
      title: "Aplicaciones medioambientales",
      url: "/bombeo/aplicaciones-medioambientales",
    },
    {
      title: "Dosificación y desinfección",
      url: "/bombeo/dosificacion-desinfeccion",
    },
    {
      title: "Sistemas de energías renovables",
      url: "/bombeo/sistemas-energias-renovables",
    },
  ]

  return (
    <Layout>
      <div className="container">
        <div className="margin-y-lg">
          <div className="grid gap-xl">
            <div className="col-12">
              <h1 className="text-md">
                <Link className="text-decoration-none" to="/bombeo">
                  Bombeo
                </Link>
              </h1>
            </div>
            <div className="col-12 col-4@sm">
              <ResponsiveSidebar title="Bombeo">
                <SidenavNavigation
                  sidenavNavigationListItems={sidenavNavigationListItems}
                />
              </ResponsiveSidebar>
            </div>
            <div className="col-12 col-8@sm">
              <div className="margin-y-sm text-component">
                <h2 className="text-sm">{bombeo.frontmatter.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: bombeo.html }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BombeoTemplate
